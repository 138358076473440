.page_intro {
	width: 90%;
	margin-left: 5%;
}
.page_intro h1 {
	text-align: center;
}
.page_intro div {
	padding: 20px;
	background-color: #729fcf;
	border: 2px solid green;
	display: table;
}
.page_intro img {
	float: left;
	margin-right: 1em;
}
.page_intro p {
	color: white;
	font-family: Arial, sans-serif;
	font-size: 18pt;
	display: table-cell;
	vertical-align: middle;
}
.page_intro a {
	float: right;
	padding: 10px;
	background-color: white;
	color: green;
	text-decoration: none;
	border: 2px solid transparent;
	border-radius: 10px;
	transition: 0.3s;
}
.page_intro a:hover {
	color: #729fcf;
	border-color: green;
}
