.page_index .sidebar {
	width: 35%;
}
.page_index .sidebar img {
	width: 100%;
	margin-bottom: 10px;
}
.page_index .content {
	font-size: 11pt;
	font-size: 2.2vw;
	
	@media (max-width: 630px) {
		font-size: 10pt;
		font-size: 2.2vw;
	}
		
	@media (max-width: 550px) {
		font-size: 9pt;
		font-size: 2.2vw;
	}
		
	@media (max-width: 450px) {
		font-size: 8pt;
		font-size: 2.2vw;
	}
		
}
.page_index .doit {
	float: right;
	font-size: 20pt;
	font-size: 2.8vw;
	font-weight: bold;
	padding: 15px 18px;
	border-radius: 4px;
	background-color: #7ecfb1;
	color: white;
	transition: 0.3s;
	background-color: yellow;
	color: green
}
.page_index .doit:hover {
	color: red;
}
