.page_urchins p {
	font-size: 15pt;
	font-size: 2.3vw;
	margin: 10px 20px;
}
.page_urchins table {
	width: 100%;
}
.page_urchins td {
	width: 25%;
	padding: 10px;
	text-align: center;
	vertical-align: top;
}
.page_urchins td img {
	width: 100%;
}
.page_urchins tr div {
	position: relative;
	border-width: 4px;
	border-style: solid;
	border-radius: 10px;
	overflow: hidden;
	font-size: 0;
}
.page_urchins tr.correct div {
	border-color: green;
}
.page_urchins tr.wrong div {
	border-color: red;
}
.page_urchins tr div:after {
	font-size: 16pt;
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 10px;
	border-radius: 10px 0 0 0;
}
.page_urchins tr.correct div:after {
	content: "Yes!";
	color: green;
	background-color: #ccffcc;
}
.page_urchins tr.wrong div:after {
	content: "No!";
	color: red;
	background-color: #ffcccc;
}
