* {
	box-sizing: border-box;
}
body {
	background-color: black;
	color: white;
	font-family: Arial, sans-serif;
	padding: 20px;
}
header {
	padding-bottom: 15px;
	border-bottom: 4px solid white;
	
	h1 {
		font-size: 22pt;
		font-size: 3.7vw;
		font-weight: normal;
		margin: 0;
		padding: 0;
		display: inline-block;
		width: 45%;

		@media (max-width: 750px) {
			font-size: 19pt;
			font-size: 3.7vw;
		}
		
		@media (max-width: 650px) {
			font-size: 16pt;
			font-size: 3.7vw;
		}
		
		@media (max-width: 550px) {
			font-size: 13pt;
			font-size: 3.7vw;
		}
		
		@media (max-width: 470px) {
			font-size: 10pt;
			font-size: 3.6vw;
		}
		
		@media (max-width: 370px) {
			font-size: 7pt;
			font-size: 3.5vw;
		}
		
	}
	
	.scripps_logo {
		width: 35%;
		padding: 0 2%;
	}
	
	.carmabi_logo {
		width: 15%;
	}
}

a {
	text-decoration: none;
}

nav {
	width: 100%;
	padding: 10px 0;
	background-color: #777;
	margin-bottom: 10px;
	border-radius: 0 0 4px 4px;
}

nav a {
	display: inline-block;
	margin-left: 10px;
	padding: 5px 8px;
	border-radius: 4px;
	background-color: #7ecfb1;
	color: white;
	transition: 0.3s;
}
nav a.cta {
	background-color: yellow;
	color: green
}
nav a:hover {
	color: black;
}

.sidebar, .content {
	display: inline-block;
}
.content {
	width: 60%;
	margin-left: 2%;
	vertical-align: top;
	margin-top: 0;
}

.content a {
	color: inherit;
	background-color: #333;
	padding: 1px 3px;
	border-radius: 4px;
	transition: 0.3s;
}
.content a:hover {
	color: #7ecfb1;
}
