#map, .page_map p {
	width: 80%;
	margin-left: 10%;
	margin-top: 20px;
	position: relative;
}
.page_map {
	overflow: scroll;
}
.page_map .submit {
	margin-left: 10%;
}
#map.lineMap img {
	width: 100%;
	border: 1px solid #333;
	border-radius: 20px;
}
#map .star {
	color: red;
	font-size: 16px;
	margin-left: -0.5em;
	margin-top: -0.65em;
	text-align: center;
	cursor: pointer;
	position: absolute;
	width: 20px;
	height: 20px;
	line-height: 1;
	z-index: 1;
}
#map .star.active {
	border: 2px solid green;
	border-radius: 10px;
}
#survey {
	display: none;
	width: 30em;
	position: absolute;
	margin-left: calc(50% - 15em);
	z-index: 2;
	
	@media (max-width: 499px) {
		font-size: 10pt;
	}
	@media (max-width: 399px) {
		font-size: 8pt;
	}
}
